import React from "react"; 
import { blog } from "../../dummydata";
import Title from "../common/title/Title";

const Hblog = () => {
  return (
    <>
      <section className="blog py-12 bg-gray-100">
        <div className="container mx-auto px-4">
          {/* Title component */}
          <Title subtitle="OUR BLOG" title="Recent From Blog" />
          
          {/* Blog grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
            {blog.slice(0, 3).map((val, index) => (
              <div
                key={index}
                className="bg-customBlue rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl"
              >
                {/* Image */}
                <div className="img">
                  <img
                    src={val.cover}
                    alt={val.title}
                    className="w-full h-48 sm:h-56 lg:h-64 object-cover"
                  />
                </div>

                {/* Text Content */}
                <div className="text p-4 sm:p-6">
                  <div className="admin flex flex-wrap justify-between text-sm text-gray-50 mb-4">
                    <span className="mb-2 sm:mb-0">
                      <i className="fa fa-user mr-2"></i>
                      <label>{val.type}</label>
                    </span>
                    <span className="mb-2 sm:mb-0">
                      <i className="fa fa-calendar-alt mr-2"></i>
                      <label>{val.date}</label>
                    </span>
                    <span className="mb-2 sm:mb-0">
                      <i className="fa fa-comments mr-2"></i>
                      <label>{val.com}</label>
                    </span>
                  </div>
                  <h1 className="text-xl font-semibold text-gray-800 mb-2">{val.title}</h1>
                  <p className="text-gray-50 text-sm sm:text-base overflow-hidden text-ellipsis whitespace-nowrap max-h-16 sm:max-h-20">
                    {val.desc}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Hblog;
