// import React from 'react';
// import Title from '../common/title/Title';
// import { servicesCard } from '../../dummydata';

// const HAbout = () => {
//   return (
//     <>
//       <section className='mx-auto px-10 top-12'>
//         <div className="max-w-7xl mx-auto px-9">
//           <Title title="Explore Our Popular Online Services" />
//           <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9">
//             {servicesCard.slice(0, 3).map((val) => (
//               <div key={val.id} className="bg-customBlue shadow-lg rounded-lg overflow-hidden">
//                 <div className="content">
//                   <div className="flex flex-col items-center p-6">
//                     <div className="img mb-4">
//                       <img
//                         src={val.cover}
//                         alt={val.ServicesName}
//                         className="w-full h-48 object-cover rounded-md"
//                       />
//                     </div>
//                     <div className="text-center">
//                       <h1 className="text-xl font-semibold text-gray-800">
//                         {val.ServicesName}
//                       </h1>
//                       <p className="mt-2 text-gray-100">{val.desc}</p>
//                     </div>
//                   </div>
//                   <div className="p-4">
//                     <button className="w-full bg-TealBlue text-gray-50 py-2 px-4 rounded hover:bg-gray-400 transition duration-300">
//                       Click here
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default HAbout;





import React from "react"; 
import Slider from "react-slick"; // Import react-slick
import "slick-carousel/slick/slick.css"; // Slick styles
import "slick-carousel/slick/slick-theme.css";

// Sample data for services
const servicesCard = [
  {
    id: 1,
    cover: "/courses/cc1.png",
    ServicesName:"Personality Assessment ",
    desc: "For enhanced data efficiency for recruiters and employers.",
  },
  {
    id: 2,
    cover: "/courses/c1.png",
    ServicesName: "3D CVs (AI Based-for Different JDs)",
    desc: "Easily create a standout CV tailored to your unique skills and experiences.",
  },
  {
    id: 3,
    cover: "/courses/c4.png",
    ServicesName: "Resume Services",
    desc: "Variety of desirable job openings, making it easy to apply and find opportunities that fit your skills.",
  },
  {
    id: 6,
    cover: "/courses/cc3.png",
    ServicesName: "Consultancy & Advisory Services",
    desc: "Personalized mock interview services to boost your interview success.",
  },
  {
    id: 5,
    cover: "/courses/c6.png",
    ServicesName: "Memberships",
    desc: "Designed for job seekers to improve and polish your skills to elevate your career.",
  },
  {
    id: 4,
    cover: "/courses/c5.png",
    ServicesName: "Offer Calculator",
    desc: "Evaluate and compare multiple job offers based on key factors like salary, benefits, and growth opportunities.",
  },
  {
    id: 7,
    cover: "/courses/cc9.png",
    ServicesName: "Mentoring Programs",
    desc: "Training and mentorship to help individuals gain the skills and confidence needed.",
  },
  {
    id: 8,
    cover: "/courses/cc8.png",
    ServicesName: "Career Placement Services",
    desc: "Tools to evaluate your skills and personality to identify suitable career paths.",
  },
  {
    id: 9,
    cover: "/courses/c2.png",
    ServicesName: "Counselling Services",
    desc: "Personalized strategies and insights to help you navigate challenges, enhance performance, and achieve your goals.",
  },
];

// Component for individual service card
const ServiceCard = ({ cover, ServicesName, desc }) => (
  <div className="container mx-auto px-6 py-5">
  <div className="service-card p-4 bg-[#ebecf4] rounded-lg shadow-md transform transition-all duration-300 hover:scale-105 hover:shadow-xl">
  
    <img src={cover} alt={ServicesName} className="w-full  object-cover rounded-t-lg" />
    <div className="size-40">
      <h3 className=" text-sm font-semibold text-center">{ServicesName}</h3>
      {/* <p className="text-black mt-2 text-xs">{desc}</p> */}
    </div>
  </div></div>
);

// Slider settings
const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,  // Default to 4 slides on large screens
  slidesToScroll: 1,
  autoplay: true, // Enable automatic sliding
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024, // Large screens: 2 slides per view
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768, // Tablet: 2 slides per view
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 640, // Small screens: 1 slide per view
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

// Component to display all services
const ServicesList = () => (
  <section className="services-section py-8 ">
    <div className="container px-8">
      <h2 className="text-3xl font-bold text-center text-[#e02424] mb-8">Explore Our  Services</h2>
      <Slider {...sliderSettings}>
        {servicesCard.map((service) => (
          
          <div key={service.id} className="px-2 size-40 text-center">
            <ServiceCard

              cover={service.cover}
              ServicesName={service.ServicesName}
              // desc={service.desc}
            />
          </div>
        ))}
      </Slider>
    </div>
  </section>
);

export default ServicesList;
