
// import React from "react";
// import { awrapper } from "../../dummydata";

// const Awrapper = () => {
//   return (
//     <>
//       <section className="py-12 bg-customBlue">
//         <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 sm:px-6 lg:px-8">
//           {awrapper.map((val, index) => (
//             <div
//               key={index}
//               className="box flex items-center bg-TealBlue p-4 shadow-md rounded-lg transition hover:shadow-lg"
//             >
//               <div className="img mr-4">
//                 <img
//                   src={val.cover}
//                   alt={val.title}
//                   className="w-16 h-16 object-cover rounded-full"
//                 />
//               </div>
//               <div className="text-gray-50">
//                 <h1 className="text-lg sm:text-xl font-bold">{val.data}</h1>
//                 <h3 className="text-sm sm:text-base text-gray-100">{val.title}</h3>
//               </div>
//             </div>
//           ))}
//         </div>
//       </section>
//       <div className="margin"></div>
//     </>
//   );
// };

// export default Awrapper;
// import React from "react";
// import { awrapper } from "../../dummydata";

// const Awrapper = () => {
//   return (
//     <>
//       <section className="py-12 bg-customBlue">
//         <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4 sm:px-6 lg:px-8">
//           {awrapper.map((val, index) => (
//             <div
//               key={index}
//               className="box flex items-center bg-TealBlue p-4 sm:p-6 shadow-md rounded-lg transition-transform transform hover:scale-105 hover:shadow-lg"
//             >
//               {/* Image */}
//               <div className="img mr-4">
//                 <img
//                   src={val.cover}
//                   alt={val.title}
//                   className="w-12 h-12 sm:w-16 sm:h-16 object-cover rounded-full"
//                 />
//               </div>
//               {/* Text */}
//               <div className="text-gray-50">
//                 <h1 className="text-base sm:text-lg lg:text-xl font-bold">
//                   {val.data}
//                 </h1>
//                 <h3 className="text-sm sm:text-base text-gray-100">
//                   {val.title}
//                 </h3>
//               </div>
//             </div>
//           ))}
//         </div>
//       </section>
//       <div className="margin"></div>
//     </>
//   );
// };

// export default Awrapper;
import React from "react"; 
import { awrapper } from "../../dummydata";

const Awrapper = () => {
  return (
    <>
      <section className="py-12 bg-customBlue">
        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 px-4 sm:px-6 lg:px-8">
          {awrapper.map((val, index) => (
            <div
              key={index}
              className="box flex items-center bg-TealBlue p-4 sm:p-6 shadow-md rounded-lg transition-transform transform hover:scale-105 hover:shadow-lg"
            >
              {/* Image */}
              <div className="img mr-4">
                <img
                  src={val.cover}
                  alt={val.title}
                  className="w-12 h-12 sm:w-16 sm:h-16 lg:w-20 lg:h-20 object-cover rounded-full"
                />
              </div>
              {/* Text */}
              <div className="text-gray-50">
                <h1 className="text-base sm:text-lg lg:text-xl xl:text-2xl font-bold">
                  {val.data}
                </h1>
                <h3 className="text-sm sm:text-base text-gray-100">
                  {val.title}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </section>
      <div className="margin"></div>
    </>
  );
};

export default Awrapper;
