import React, { useState } from "react";
import { Link } from 'react-router-dom';

const PersonalInformationForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    age: "",
    gender: "",
    country: "",
    education: "",
    occupation: "",
    purpose: "",
    consent: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted: ", formData);
    alert("Form Submitted Successfully!");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-cover bg-no-repeat"
    style={{ backgroundImage: "url('ap.img.png')" }}
    
    >
      <div className="p-10">
      <form
        onSubmit={handleSubmit}
        className="bg-white bg-opacity-80 p-8 rounded-lg shadow-lg h-full max-w-3xl"
      >
        <h2 className="text-2xl font-bold text-TealBlue mb-4">
          Personal Information Form
        </h2>

        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-TealBlue">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
            placeholder="Enter your name"
            
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-TealBlue">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
            placeholder="Enter your email"
           
          />
        </div>

        <div className="mb-4">
          <label htmlFor="age" className="block text-sm font-medium text-TealBlue">
            Age
          </label>
          <input
            type="number"
            id="age"
            name="age"
            value={formData.age}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
            placeholder="Enter your age"
           
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-TealBlue">Gender</label>
          <div className="mt-1 space-y-2 text-TealBlue">
            {["Male", "Female", "Other", "Prefer not to say"].map((gender) => (
              <label key={gender} className="inline-flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value={gender}
                  checked={formData.gender === gender}
                  onChange={handleChange}
                  className="text-TealBlue focus:ring-customBlue"
                />
                <span className="ml-2">{gender}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="country" className="block text-sm font-medium text-TealBlue">
            Country/City
          </label>
          <input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
            placeholder="Enter your country or city"
            
          />
        </div>

        <div className="mb-4">
          <label htmlFor="education" className="block text-sm font-medium text-TealBlue">
            Education Level
          </label>
          <select
            id="education"
            name="education"
            value={formData.education}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-white text-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
           
          >
            <option value="" disabled
             className=" text-TealBlue">
              Select your education level
            </option>
            {[
              
              "High School",
              "Associate Degree",
              "Bachelor’s Degree",
              "Master’s Degree",
              "Doctorate/PhD",
              "Other",
            ].map((level) => (
              <option  className=" text-TealBlue"key={level} value={level}>
                {level}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="occupation" className="block text-sm font-medium text-TealBlue">
            Occupation
          </label>
          <input
            type="text"
            id="occupation"
            name="occupation"
            value={formData.occupation}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
            placeholder="Enter your occupation"
           
          />
        </div>

        <div className="mb-4">
          <label htmlFor="purpose" className="block text-sm font-medium text-TealBlue">
            Purpose for Taking the Test
          </label>
          <textarea
            id="purpose"
            name="purpose"
            value={formData.purpose}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
            placeholder="E.g., self-discovery, career guidance, personal development"
        
          ></textarea>
        </div>

        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="consent"
              checked={formData.consent}
              onChange={handleChange}
              className="text-customBlue focus:ring-customBlue"
              
            />
            <span className="ml-2 text-sm text-TealBlue">
              I agree to the terms and conditions.
            </span>
          </label>
        </div>
        <Link
        to="/PersonalityAssessment">
        <button
          type="submit"
          className="bg-customBlue text-white font-semibold py-2 px-52 rounded-md hover:bg-opacity-90 transition-all duration-300">
          Submit
        </button>
        </Link>
        
      </form>
      </div>
    </div>
  );
};

export default PersonalInformationForm;
