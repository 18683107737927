import React from 'react';
import { Link } from 'react-router-dom';

const RegistHome = () => {
  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('ap.img.png')" }}
    >
      <div className="bg-white bg-opacity-80 p-8 rounded-lg shadow-md w-full max-w-lg text-center">
        <h1 className="text-3xl font-bold text-TealBlue mb-6">Assess your personality</h1>
        <p className="text-lg text-gray-700 mb-4">
          Thank you for registering! You can explore more features or go back to the registration page.
        </p>
        <div className="space-y-4">
          
          <Link 
          to="/Information"
         
            className="block w-full bg-customBlue text-white py-2 px-4 rounded-md hover:bg-TealBlue transition-colors">
           
          
           Personality Test
          
          </Link>
          <Link
            to="/"
            className="block bg-TealBlue text-white py-2 px-4 rounded-md hover:bg-customBlue transition-colors"
          >
             Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegistHome;



