// import React from 'react';
 

// const Head = () => {
//   return (
//     <section
//       className="bg-gray text-white py-6"
//       style={{
//         backgroundImage: "url('ap.img.png')", 
//         backgroundPosition: "right center",
//         backgroundSize: "cover", 
//       }}
//     >
//       <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center px-4">
//         <div className="logo text-2xl font-bold mb-4 sm:mb-0">
//           <h1 className="flex items-baseline">
//             <span className="text-white">Sanjeed</span>
//             <span className="text-red-500">a!</span>
//           </h1>
//         </div>
//         <div className="social flex space-x-4">
//           <ul className="flex justify-center space-x-4 my-4">
//             <li>
//               <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" title="Visit our Facebook page">
//                 <i className="fab fa-facebook-f text-white hover:text-blue-500"></i>
//               </a>
//             </li>
//             <li>
//               <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" title="Visit our instagram page">
//                 <i className="fab fa-instagram text-white hover:text-pink-500"></i>
//               </a>
//             </li>
//             <li>
//               <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" title="Visit our youtube page">
//                 <i className="fab fa-youtube text-white hover:text-red-500"></i>
//               </a>
//             </li>
//             <li>
//               <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" title="Visit our Twitter page">
//                 <i className="fab fa-twitter text-white hover:text-blue-400"></i>
//               </a>
//             </li>
//             <li>
//               <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" title="Visit our linkedin page">
//                 <i className="fab fa-linkedin-in text-white hover:text-blue-700"></i>
//               </a>
//             </li>
//           </ul>
          
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Head;
import React from 'react';

const Head = () => {
  return (
    <section
      className="py-6  bg-[#dcdce8]"

      // style={{
      //     backgroundImage: "url('hero.png')",
      //     backgroundPosition: "center center ",
      //     backgroundSize: "cover ",
      //   }} 
        >
      <div className="container max-w-screen-xl mx-auto flex flex-col sm:flex-row justify-between items-center px-4">
        {/* Logo Section */}
        <div className="logo text-3xl font-bold mb-6 sm:mb-0 ">
          <h1 className="flex items-baseline space-x-1">
            <span className="text-Red">Sanjeed</span>
            <span className="text-[#081b9c]">a!</span>
          </h1>
        </div>

        {/* Social Icons Section */}
        <div className="social flex space-x-4">
          <ul className="flex justify-center items-center space-x-6">
            <li>
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our Facebook page"
                className="text-black hover:text-blue-500 transition duration-300"
              >
                <i className="fab fa-facebook-f text-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our Instagram page"
                className="text-black hover:text-pink-500 transition duration-300"
              >
                <i className="fab fa-instagram text-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our YouTube page"
                className="text-black hover:text-red-500 transition duration-300"
              >
                <i className="fab fa-youtube text-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our Twitter page"
                className="text-black hover:text-blue-400 transition duration-300"
              >
                <i className="fab fa-twitter text-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our LinkedIn page"
                className="text-black hover:text-blue-700 transition duration-300"
              >
                <i className="fab fa-linkedin-in text-xl"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
     </section>
  );
};

export default Head;
