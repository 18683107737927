
import React, { useState } from 'react';

const JobDescriptionForm = () => {
  const [page, setPage] = useState(1);
  const nextPage = () => setPage((prev) => Math.min(prev + 1, 3));
  const prevPage = () => setPage((prev) => Math.max(prev - 1, 1));
  return (
  <div className="min-h-screen flex items-center justify-center bg-cover bg-no-repeat"
  style={{ backgroundImage: "url('ap.img.png')" }}
  >
    <div className="p-10">
      <div className="bg-white bg-opacity-80 p-8 rounded-lg shadow-lg h-full max-w-3xl">
        <table className="w-full  text-white">
          <tbody>
            {page === 1 && (
              <>
              <h1 className="text-TealBlue text-3xl font-semibold mb-6">Job Success Form</h1>
              <tr className="border-b border-TealBlue">
              <td className="section py-2 text-TealBlue font-semibold">Position Information</td>
              </tr>
              <tr>
                <td className="py-4 text-TealBlue">Position Title:</td>
              <td>
              <input className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
              type="text"
              placeholder="Enter the position title"/>
              </td>
              </tr>
              <tr>
                <td className="py-4 text-TealBlue">
                  <label htmlFor="division">Division/Department:</label>
                  </td>
                  <td>
                  <input id="division"
                  className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                  type="text"
                  title="Enter the division or department"
                  placeholder="Enter the division/department"/>
                </td>
              </tr>
              <tr>
                <td className="py-4 text-TealBlue">
                 <label htmlFor="reportsTo">Reports To:</label>
                </td>
                <td>
                  <input id="reportsTo"
                  className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                  type="text"
                  title="Enter the reporting manager or supervisor"
                  placeholder="Enter who this position reports to"/>
                </td>
              </tr>
              <tr>
                <td className="py-2 text-TealBlue">
                  <label htmlFor="spanOfControl">Span of Control:</label>
                </td>
                <td>
                  <input id="spanOfControl"
                  className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                  type="text"
                  title="Enter the span of control"
                  placeholder="Describe the span of control"/>
                </td>
              </tr>
              <tr>
                <td className="py-4 text-TealBlue">
                  <label htmlFor="jobGrade">Job Grade:</label>
                </td>
                <td>
                  <input id="jobGrade"
                  className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                  type="text"
                  title="Enter the job grade"
                  placeholder="Enter job grade"/>
                </td>
              </tr>
              <tr>
                <td className="py-4 text-TealBlue">
                  <label htmlFor="jobLocation">Job Location:</label>
                </td>
                <td>
                  <input id="jobLocation"
                  className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                  type="text"
                  title="Enter the job location"
                  placeholder="Enter job location"/>
                </td>
              </tr>
              <tr>
                <td className="py-4 text-TealBlue">
                  <label htmlFor="departmentGoals">Department Goals:</label>
                </td>
                <td>
                  <textarea id="departmentGoals" rows="2"
                  className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                  title="Enter department goals"
                  placeholder="Enter the department goals">
                  </textarea>
                </td>
              </tr>
              </>
            )}
            {page === 2 && (
              <>
              <h1 className="text-TealBlue text-3xl font-semibold mb-6">Success Factors at Job</h1>
              <tr className="border-b border-TealBlue">

              </tr>
              <table>
                <tbody>
                <tr>
                    <td className="py-9 text-TealBlue">
                      <label htmlFor="KeyReportAreas">Key Report Areas:</label>
                    </td>
                <td>
                  <textarea id="KeyReportAreas" rows="2"
                  className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                  title="Enter department Areas"
                  placeholder="Enter the department Areas">

                  </textarea>
                </td>
                </tr>
              <tr>
                <td className=" py-2 text-TealBlue">
                  <label htmlFor="jd">JD (4 to 5 lines):</label>
                </td>
                <td>
                  <textarea id="jd" rows="4"
                  className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                  title="Enter job description"
                  placeholder="Enter job description"/>
                </td>
              </tr>
              <tr>
                <td className="py-4 text-TealBlue">
                  <label htmlFor="projectsRequired">Projects Required:</label>
                </td>
                <td>
                  <textarea id="projectsRequired" rows="2"
                  className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
                  title="Enter the projects required for this position"
                  placeholder="Enter required projects"/>
                </td>
              </tr>
              <tr>
                <td className="py-4 text-TealBlue">
                  <label htmlFor="majorChallenges">Major Challenges/Problems</label>
                </td>
                <td>
                  <div className="overflow-x-auto p-4">
                    <table className="min-w-full bg-white shadow-md rounded-lg">
                      <thead>
                        <tr className="bg-customBlue text-TealBlue uppercase text-sm leading-normal">
                        <th className="py-3 px-6 text-left">Work</th><th className="py-3 px-6 text-left">Team</th>
                        <th className="py-3 px-6 text-left">Process</th>
                        <th className="py-3 px-6 text-left">Client</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-600 text-sm">
                      {[...Array(3)].map((_, index) => (
                        <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                          {["Work", "Team", "Process", "Client"].map((placeholder, idx) => (
                            <td key={idx} className="py-3 px-6">
                              <input type="text"
                              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-customBlue"
                              title={`Enter ${placeholder.toLowerCase()}-related challenge`}
                              placeholder={`Enter ${placeholder.toLowerCase()} challenge`}/>
                            </td>))}
                          </tr>))}
                        </tbody>
                        </table>
                        </div>
                      </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
              {page === 3 && (
              <>
              <h1 className="text-TealBlue text-3xl font-semibold mb-6">Required Expertise for Success</h1>
              <tr className="border-b border-TealBlue"></tr>
              <tr>
            <td className="py-4 text-TealBlue">
              <label htmlFor="educationRequired">Education Required:</label>
            </td>
            <td>
              <input id="educationRequired"
              className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
              type="text"
              title="Enter the education requirement"
              placeholder="Enter education requirement"/>
            </td>
          </tr>
          <tr>
            <td className="py-4 text-TealBlue">
              <label htmlFor="experienceRequired">Trainings/Certification</label>
            </td>
            <td>
              <input id="experienceRequired"
              className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
              type="text"
              title="Enter the experience required"
              placeholder="Enter experience requirement"/>
            </td>
          </tr>
          <tr>
            <td className="py-4 text-TealBlue">
              <label htmlFor="experienceRequired">Experience Required:</label>
            </td>
            <td>
              <input
              id="experienceRequired"
              className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
              type="text"
              title="Enter the experience required"
              placeholder="Enter experience requirement"/>
            </td>
          </tr>
          <tr>
            <td className="py-4 text-TealBlue">
              <label htmlFor="personalityRequired">Personality Required:</label>
            </td>
            <td>
              <textarea id="personalityRequired" rows="2"
              className="w-full px-3 py-2 bg-white text-black rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
              title="Enter the required personality traits"
              placeholder="Enter required personality traits">

              </textarea>
            </td>
          </tr>
          </>)}
        </tbody>
        </table>
        <div className="flex justify-between mt-6">
          {page > 1 && (
            <button onClick={prevPage}
            className="bg-gray-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-opacity-90 transition-all duration-300">
              Previous
            </button>
          )}
          {page < 3 ? (
            <button onClick={nextPage}
            className="bg-customBlue text-white font-semibold py-2 px-4 rounded-md hover:bg-opacity-90 transition-all duration-300">
              Next
            </button>
             ) : (
              <button
                className="bg-customBlue text-white font-semibold py-2 px-4 rounded-md hover:bg-opacity-90 transition-all duration-300"
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDescriptionForm;
