import React, { useState } from "react";

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState(""); // For managing input value
  const [isOpen, setIsOpen] = useState(false); // For toggling the search bar

  const handleSearch = () => {
    // Perform your search action here
    console.log("Searching for:", searchQuery);
  };

  return (
    <div className="relative">
      {/* Open Search Button */}
      {!isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          className="lg:hidden flex items-center px-4 py-2 bg-blue-600 text-white rounded-md fixed right-10 -mt-4 -mr-1"
        >
          <i class="fa fa-search " aria-hidden="true"></i>
        </button>
      )}

      {/* Search Bar */}
      {isOpen && (
        <div className="lg:hidden flex items-center px-10 space-x-2">
          <input
            type="text"
            className="px-4 py-2 rounded-md text-sm bg-blue-700 text-black focus:outline-none relative right-14"
            placeholder="Search here..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button
            onClick={handleSearch}
            className="px-2 py-2  text-blue-700  relative right-24 rounded-sm"
          >
             <i class="fa fa-search" aria-hidden="true"></i>
          </button>
          <button
            onClick={() => setIsOpen(false)}
            className="px-3 py-2 bg-red-600 text-white rounded-md relative right-28"
          >
            ✖
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchBar;