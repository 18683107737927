import React from "react";
import Back from "../common/back/Back";
import PriceCard from "./PriceCard";
import Faq from "./Faq";

const Pricing = () => {
  return (
    <>
      
      <section className="py-16" 
      style={{backgroundImage: "url('ap.img.png')",
        backgroundPosition: "center right",
        backgroundSize: "contain",
        }}>
      <Back title="Choose The Right Plan" />
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <PriceCard />
          </div>
        </div>
      </section>
      <Faq />
    </>
  );
};

export default Pricing;
