
// import React from "react";
// import Back from "../common/back/Back";
// import BlogCard from "./BlogCard";

// const Blog = () => {
//   return (
//     <>
//       <section
//         className="py-12 hero bg-cover text-black flex justify-center"
//         style={{
//           backgroundImage: "url('ap.img.png')",
//           backgroundPosition: "center",
//           backgroundSize: "cover", 
//         }}
//       >
//         <div className="container mx-auto px-4 sm:px-6 lg:px-8">
//           <Back title="Blog Posts" />
//           <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            
//             <BlogCard />
           
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Blog;
import React from "react";
import Back from "../common/back/Back";
import BlogCard from "./BlogCard";
// Dummy blog data array to simulate multiple blog posts (replace with real data later)
const blogData = [
  { title: "Blog Post 1", description: "Description for blog post 1", image: "path/to/image1.jpg" },
  { title: "Blog Post 2", description: "Description for blog post 2", image: "path/to/image2.jpg" },
  { title: "Blog Post 3", description: "Description for blog post 3", image: "path/to/image3.jpg" },
  // Add more blog objects as needed
];

const Blog = () => {
  return (
    <>
      <section
        className="py-12 hero bg-cover text-black flex justify-center"
        style={{
          backgroundImage: "url('ap.img.png')",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <Back title="Blog Posts" />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Map over blog data to render multiple BlogCards */}
            {blogData.map((blog, index) => (
              <BlogCard
                key={index}
                title={blog.title}
                description={blog.description}
                image={blog.image}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
