import React from "react";

const Title = ({ subtitle, title }) => {
  return (
    <>
      <div className='text-center mb-8 px-4 '>
        <h3 className='text-base md:text-lg font-semibold uppercase text-gray-900'>{subtitle}</h3>
        <h1 className='text-2xl sm:text-3xl md:text-4xl font-bold mt-2 text-gray-900'>{title}</h1>
      </div>
    </>
  );
};

export default Title;

