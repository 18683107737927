// import React, { useState } from 'react';
// import Head from './Head';
// // import "./header.css";
// import { Link } from 'react-router-dom';

// const Header = () => {
//   const [click, setClick] = useState(false)
//   return (
//     <>
//     <Head/>
//     <header className="w-full bg-[#004D60] py-1 relative z-10 shadow-md ">
//         <nav className='flexSB flex justify-between items-center px-5 '>
//         <div className="flex items-center space-x-4">
//         <ul
//          className={`${click ? "mobile-nav block absolute top-16 right-5 bg-[#00838F] p-4 rounded-md"  : "flexSB  items-center space-x-6 md:flex hidden " }`}onClick={()=> setClick(false)}
         
//          >
//            <li className="relative group">
//             <li>
//             <Link to="/#Home" className="text-white  text-base hover:bg-[#58a2a9]">
//               <i className="fa fa-home" aria-hidden="true"></i> Home
//             </Link></li>
//             <div className="dropdown_menu top-full left-0 w-48 mt-2 bg-[#00838F] shadow-lg rounded group-hover:block md:flex hidden">
//               <ul>
//                 <li>
//                   <Link to="/about#About " className="text-white px-4 py-2 text-sm hover:bg-[#58a2a9]">About</Link>
//                 </li>
//                 <li>
//                   <Link to="contact/#Contact" className="text-white px-4 py-2 text-sm hover:bg-[#58a2a9]">
//                     <i className="fa fa-phone-square" aria-hidden="true"></i> Contact
//                   </Link>
//                 </li>
//                 <li><Link to="pricing#Pricing" className="text-white px-4 py-2 text-sm hover:bg-[#58a2a9]">Pricing</Link></li>
//               </ul>
//             </div>
//           </li >
//           <li className="relative group">
//             <li><Link to="/services#Services" className="text-white px-4 py-2 text-base hover:bg-[#58a2a9]">Services</Link></li>
//             <div className="dropdown_menu top-full left-0 w-48 mt-2 bg-[#00838F] shadow-lg rounded group-hover:block md:flex hidden">
//               <ul>
//                 <li><Link to="FinancialOffer#FinancialOffer" className="text-white px-4 py-2 text-sm hover:bg-[#58a2a9]">Offer Calculator</Link></li>
//                 <li>
//                   <Link to="/#Student-profile" className="text-white px-4 py-2 text-sm hover:bg-[#58a2a9]">Student Profile</Link>
//                 </li>
//                 <li>
//                   <Link to="/cv-upload#Upload_CV" className="text-white px-4 py-2 text-sm hover:bg-[#58a2a9]">Upload CV</Link>
//                 </li>
//                 <li>
//                   <a href="#Resume Building" className="text-white px-4 py-2 text-sm hover:bg-[#58a2a9]">
//                     Resume Building <i className="fas fa-caret-right"></i>
//                   </a>
//                 </li>
//               </ul>
//             </div>
//           </li>
//           <li>
//             <li><Link to="team#Team" className="text-white px-4 py-2 text-base hover:bg-[#58a2a9]">Team</Link></li>
//           </li>
//           <li >
//              <li><Link to="blog#Blog" className="text-white px-4 py-2 text-base hover:bg-[#58a2a9]">Blog</Link></li>
//           </li>
//           <li className="relative group">
//             <Link to="/#Registration" className="text-white px-4 py-2 text-base hover:bg-[#58a2a9]">Registration</Link>
//             <div className="dropdown_menu-4 top-full left-0 w-48 mt-2 bg-[#00838F] shadow-lg rounded group-hover:block md:flex hidden">
//             <ul>
//             <li><Link to="Registration#Registration" className="text-white px-4 py-2 text-sm hover:bg-[#58a2a9]">I am Professional</Link></li>  
//                 <li><Link to="Registration#Registration" className="text-white px-4 py-2 text-sm hover:bg-[#58a2a9]">I am a Student</Link></li>
//                 <li><Link to="Registration#Registration" className="text-white px-4 py-2 text-sm hover:bg-[#58a2a9]"> I am a Mentor</Link></li>
//                 <li><Link to="Registration#Registration" className="text-white px-4 py-2 text-sm hover:bg-[#58a2a9]">I am a Parent</Link></li>
//                 <li><Link to="Registration#Registration" className="text-white px-4 py-2 text-sm hover:bg-[#58a2a9]"> I am Job Seeker</Link></li>
//                 <li className="relative group">
//                   <a href="#Employer" className="text-white block px-4 py-2 text-sm hover:bg-[#58a2a9]">
//                   Employer <i className="fas fa-caret-right"></i>
//                   </a>
//                   <div className="dropdown_menu-1  top-full right-36 w-48 mt-2 bg-[#00838F] shadow-lg rounded group-hover:block md:flex hidden absolute">
//                     <ul>
//                       <li>
//                         <Link to="/JobForm#JobDescriptionForm" className="text-white px-4 py-2 text-sm hover:bg-[#58a2a9]">Job Forms</Link>
//                       </li> 
//                     </ul>
//                   </div>
//                 </li>
//               </ul>  
//             </div>
//           </li>  
//         </ul>
//         </div>
       
// <div
//   className="start bg-customBlue px-6 py-4 rounded-md hover:bg-[#58a2a9] xs:px-4 xs:py-3"
//   style={{ clipPath: 'polygon(10% 0, 100% 0, 100% 100%, 0 100%)' }}
// >
//   <a href="/logo" className="text-white  px-6 py-2  rounded-md ">Log In</a>
// </div>

// <button
//   className="toggle absolute right-2  text-white text-3xl lg:hidden p-2"
//   onClick={() => setClick(!click)} // Toggles the menu state
//   aria-label={click ? "Close navigation" : "Open navigation"}
// >
//   {click ? (
//     <i className="fa fa-times"></i> // Close icon
//   ) : (
//     <i className="fa fa-bars"></i> // Hamburger icon
//   )}
// </button>
            
//         </nav>
//     </header>
//     </>
//   )
// }

// export default Header


// import React, { useState } from 'react';
// import Head from './Head';
// import { Link } from 'react-router-dom';

// const Header = () => {
//   const [click, setClick] = useState(false);

//   return (
//     <>
//       <Head />
//       <header className="w-full bg-[#041580] py-1 relative z-10 shadow-md">
//         <nav className="flex justify-between items-center px-5">
//           {/* Navigation Menu */}
//           <div className="flex items-center space-x-4">
//             <ul
//               className={`${click ? "block absolute top-16 right-5 bg-[#0620cc] p-4 rounded-md shadow-lg" : "hidden lg:flex items-center space-x-6"}`}
//               onClick={() => setClick(false)}
//             >
//               {/* Home Menu Item */}
//               <li className="relative group">
//                 <Link to="/#Home" className="text-white text-base px-4 py-2 flex items-center hover:bg-[#0620cc]">
//                   <i className="fa fa-home mr-2" aria-hidden="true"></i> Home
//                 </Link>
//                 <div className="dropdown_menu hidden group-hover:block absolute top-full left-0 w-48 mt-2 bg-[#5768d7] shadow-lg  z-10">
//                   <ul className="flex flex-col">
//                     <li>
//                       <Link
//                         to="/about#About"
//                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
//                       >
//                         About
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         to="contact/#Contact"
//                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
//                       >
//                         <i className="fa fa-phone-square mr-2" aria-hidden="true"></i> Contact
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         to="pricing#Pricing"
//                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
//                       >
//                         Pricing
//                       </Link>
//                     </li>
//                   </ul>
//                 </div>
//               </li>

//               {/* Services Dropdown */}
//               <li className="relative group">
//                 <Link to="/services#Services" className="text-white px-4 py-2 text-base hover:bg-[#0620cc] ">
//                   Services
//                 </Link>
//                 <div className="dropdown_menu hidden group-hover:block absolute top-full left-0 w-48 mt-2 bg-[#5768d7] shadow-lg  z-10">
//                   <ul className="flex flex-col">
//                     <li>
//                       <Link
//                         to="FinancialOffer#FinancialOffer"
//                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
//                       >
//                         Offer Calculator
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         to="/#Student-profile"
//                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
//                       >
//                         Student Profile
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         to="/cv-upload#Upload_CV"
//                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
//                       >
//                         Upload CV
//                       </Link>
//                     </li>
//                   </ul>
//                 </div>
//               </li>

//               {/* Team Link */}
//               <li>
//                 <Link to="team#Team" className="text-white px-4 py-2 text-base hover:bg-[#0620cc] flex items-center">
//                   Team
//                 </Link>
//               </li>

//               {/* Blog Link */}
//               <li>
//                 <Link to="blog#Blog" className="text-white px-4 py-2 text-base hover:bg-[#0620cc]">
//                   Blog
//                 </Link>
//               </li>

//               {/* Registration Dropdown */}
//               <li className="relative group">
//                 <Link
//                   to="/#Registration"
//                   className="text-white px-4 py-2 text-base hover:bg-[#0620cc]"
//                 >
//                   Registration
//                 </Link>
//                 <div className="dropdown_menu hidden group-hover:block absolute top-full left-0 w-48 mt-2 bg-[#5768d7] shadow-lg  z-10">
//                   <ul className="flex flex-col">
//                     <li>
//                       <Link
//                         to="Registration#Registration"
//                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
//                       >
//                         I am Professional
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         to="Registration#Registration"
//                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
//                       >
//                         I am Job Seeker
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         to="Registration#Registration"
//                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
//                       >
//                         I am a Student
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         to="Registration#Registration"
//                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
//                       >
//                       I am a Mentor
//                       </Link>
//                     </li>
//                     <li className="relative group">
//                       <a
//                         href="#Employer"
//                         className="text-white block px-4 py-2 text-sm hover:bg-[#0620cc] items-center"
//                       >
//                         Employer <i className="fas fa-caret-right ml-2"></i>
//                       </a>
//                       <div className="hidden group-hover:block absolute top-0 left-full w-40 mt-2 bg-[#5768d7] shadow-lg  z-10">
//                         <ul className="flex flex-col">
//                           <li>
//                             <Link
//                               to="/JobForm#JobDescriptionForm"
//                               className="text-white text-base px-4 py-2 flex items-center hover:bg-[#0620cc]"
//                             >
//                               Job Forms
//                             </Link>
//                           </li>
//                         </ul>
//                       </div>
//                     </li>
//                   </ul>
//                 </div>
//               </li>
//             </ul>
//           </div>

//           {/* Login Button */}
//           <div
//             className="absolute right-12 text-white bg-Blue px-1 py-3  hover:bg-[#0620cc] xs:px-4 xs:py-3 xs:right-3"
//   style={{ clipPath: 'polygon(10% 0, 100% 0, 100% 100%, 0 100%)' }}
//           >
//             <a
//               href="/logo"
//               className="text-white px-3 py-3 rounded-md"
//             >
//               Log In
//             </a>
//           </div>
          
//           {/* Hamburger Menu */}
//           <button
//             className="lg:hidden text-white text-3xl p-2 px-0 "
//             onClick={() => setClick(!click)}
//             aria-label={click ? "Close navigation" : "Open navigation"}
//           >
//             {click ? <i className="fa fa-times"></i> : <i className="fa fa-bars "></i>}
//           </button>
//         </nav>
//       </header>
//     </>
//   );
// };

// export default Header;



import React, { useState } from "react";
import Head from "./Head";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar ";

const Header = () => {
  const [click, setClick] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    console.log("Searching for:", searchQuery);
    // Implement search logic here
  };

  return (
    <>
      <Head />
      <header className="w-full bg-[#041580] py-1 relative z-10 shadow-md">
        <nav className="flex justify-between items-center px-5 lg:px-10">
          {/* Logo Section */}
          {/* <div className="text-white text-lg font-bold">Logo</div> */}

          {/* Navigation Menu */}
          <div className="flex items-center space-x-4">
            <ul
             className={`${click ? "block absolute top-16 right-5 bg-[#0620cc] p-4 rounded-md shadow-lg" : "hidden lg:flex items-center space-x-6"}`}onClick={() => setClick(false)}
            >
              {/* Home */}
              <li className="relative group">
                <Link
                  to="/#Home"
                  className="text-white text-base px-4 py-2 flex items-center  "
                >
                  <div className="absolute left-0 bottom-0 w-full h-[2px] bg-Red scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100 group-focus-within:scale-x-100"></div>
                  <i className="fa fa-home mr-2" aria-hidden="true"></i> Home
                  
                </Link>
                
                <div className="dropdown_menu hidden group-hover:block absolute top-full left-0 w-48 mt-2 bg-[#5768d7] shadow-lg  z-10">
                <ul className="flex flex-col">
                <li>
                <Link  to="/about#About" className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"> About</Link>
              </li>
              <li>
              <Link to="contact/#Contact" className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center">
              <i className="fa fa-phone-square mr-2" aria-hidden="true"></i> Contact
              </Link>
              </li>
              <li>
                <Link to="pricing#Pricing"
                className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center">
                  Pricing
                  </Link>
                  </li>
                  </ul>
                 </div>
                 
               </li>
               
             {/* Services Dropdown */}
               <li className="relative group">
                 <Link to="/services#Services" className="text-white px-4 py-2 text-base flex items-center ">
                 <div className="absolute left-0 bottom-0 w-full h-[2px] bg-Red scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100 group-focus-within:scale-x-100"></div>
                 <i class="fa fa-graduation-cap mr-2" aria-hidden="true"></i> Services
                 </Link>
                 <div className="dropdown_menu hidden group-hover:block absolute top-full left-0 w-48 mt-2 bg-[#5768d7] shadow-lg  z-10">
                  
                   <ul className="flex flex-col">
                     <li>                       <Link
                        to="FinancialOffer#FinancialOffer"
                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
                       >
                         Offer Calculator
                       </Link>
                     </li>
                     <li>
                       <Link
                         to="/#Student-profile"
                         className="text-white px-4 py-2 text-sm hover:underline decoration-Red flex items-center"
                       >
                         Student Profile
                       </Link>
                     </li>
                     <li>
                       <Link
                         to="/cv-upload#Upload_CV"
                         className="text-white px-4 py-2 text-sm hover:underline decoration-Red flex items-center"
                       >
                         Upload CV
                       </Link>
                     </li>
                   </ul>
                 </div>
               </li>
              {/* Team */}
              <li className="relative group">
              
                <Link
                  to="team#Team"
                  className="text-white px-4 py-2 text-base flex items-center  "
                >
                  <div className="absolute left-0 bottom-0 w-full h-[2px] bg-Red scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100 group-focus-within:scale-x-100 "></div>
                  
                  <i class="fa fa-users mr-2" aria-hidden="true"></i> Team
                </Link>
              </li>

              {/* Blog */}
              <li className="relative group">
                <Link
                  to="blog#Blog"
                  className="text-white px-4 py-4 text-base flex items-center"
                >
                   <div className="absolute left-0 bottom-0 w-full h-[2px] bg-Red scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100 group-focus-within:scale-x-100"></div>
                   <i class="fa fa-file mr-2" aria-hidden="true"></i>Blog
                </Link>
              </li>
              {/* Registration Dropdown */}
               <li className="relative group">
                 <Link
                   to="0/#Registration"
                   className="text-white px-4 py-2 text-base flex items-center"
                 >
                    <div className="absolute left-0 bottom-0 w-full h-[2px] bg-Red scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100 group-focus-within:scale-x-100"></div>
                    <i class="fa fa-id-card mr-2" aria-hidden="true"></i>Registration
                 </Link>
                 <div className="dropdown_menu hidden group-hover:block absolute top-full left-0 w-48 mt-2 bg-[#5768d7] shadow-lg  z-10">
                   <ul className="flex flex-col">
                     <li>
                       <Link
                         to="Registration#Registration"
                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
                       >
                         I am Professional
                       </Link>
                     </li>
                     <li>
                       <Link
                         to="Registration#Registration"
                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
                       >
                         I am Job Seeker
                       </Link>
                     </li>
                     <li>
                       <Link
                         to="Registration#Registration"
                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
                       >
                         I am a Student
                       </Link>
                     </li>
                     <li>
                       <Link
                         to="Registration#Registration"
                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
                       >
                       I am a Mentor
                       </Link>
                     </li>
                     <li className="relative group">
                       <a
                         href="#Employer"
                         className="text-white block px-4 py-2 text-sm hover:bg-[#0620cc] items-center"
                       >
                         Employer <i className="fas fa-caret-right ml-2"></i>
                       </a>
                       <div className="hidden group-hover:block absolute top-0 left-full w-40 mt-2 bg-[#5768d7] shadow-lg  z-10">
                         <ul className="flex flex-col">
                           <li>
                             <Link
                               to="/JobForm#JobDescriptionForm"
                               className="text-white text-base px-4 py-2 flex items-center hover:bg-[#0620cc]"
                             >
                               Job Forms
                             </Link>
                           </li>
                         </ul>
                       </div>
                     </li>
                   </ul>
                 </div>
               </li>
            </ul>
          </div>
           
          
 <SearchBar/>
          {/* Login Button */}
          <div
            className="absolute -mr-2 right-20 text-white bg-Blue px-1 py-3  hover:bg-[#0620cc] xs:px-4 xs:py-3 xs:right-3"
            style={{ clipPath: "polygon(10% 0, 100% 0, 100% 100%, 0 100%)" }}
          >
            <a href="/logo" className="text-white px-3 py-3 rounded-md">
              Log In
            </a>
          </div>

          {/* Hamburger Menu */}
          <button
            className="lg:hidden text-white text-3xl p-2 px-0"
            onClick={() => setClick(!click)}
            aria-label={click ? "Close navigation" : "Open navigation"}
          >
            {click ? <i className="fa fa-times"></i> : <i className="fa fa-bars"></i>}
          </button>
          {/* Search Bar */}
          <div className="hidden lg:flex items-center space-x-2 relative right-28 ">
          <input
    type="text"
    className="flex-1 px-4 py-2 rounded-md text-sm bg-[#f2f3f6] text-black focus:outline-none"
    placeholder="Search..."
    value={searchQuery}
    onChange={( handleSearch) => setSearchQuery( handleSearch.target.value)}
  />
            <button
              onClick={handleSearch}
              className="absolute right-3 top-0 mt-2 mr-4 text-x"
            >
            <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </nav>

       
        

      </header>
    </>
  );
};

export default Header;
