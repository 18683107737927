// import React from 'react'
import Title from '../common/title/Title'
import { homeAbout } from '../../dummydata'

import Awrapper from './AWrapper'

const AboutCard = () => {
  return (
    <>
      <section className="py-12 bg-white">
        <div className="container mx-auto flex flex-col md:flex-row px-4 sm:px-6">
          
          {/* Video Section */}
          <div className="w-full md:w-1/2 flex justify-center md:justify-start">
            <video
              className="w-full md:w-3/4 h-48 sm:h-60 md:h-80 object-cover rounded-md shadow-lg mb-6 md:mb-0"
              src="/video.mp4"
              type="video/mp4"
              autoPlay
              loop
              muted
            />
          </div>
          
          {/* Text Section */}
          <div className="w-full md:w-1/2 mt-8 md:mt-0 md:pl-10">
            <Title title="About us"/>
            <div className="mt-6 space-y-6">
              {homeAbout.map((val) => (
                <div key={val.title} className="flex flex-col sm:flex-row items-center sm:items-start space-y-4 sm:space-y-0 sm:space-x-4">
                  
                  {/* Image */}
                  <div className="w-24 h-24 sm:w-20 sm:h-20">
                    <img
                      src={val.cover}
                      alt={val.title}
                      className="w-full h-full"
                    />
                  </div>
                  
                  {/* Title and Description */}
                  <div className="text-center sm:text-left">
                    <h2 className="text-lg font-semibold">{val.title}</h2>
                    <p className="text-gray-600">{val.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Awrapper />
    </>
  )
}

export default AboutCard
