// import React from 'react'
// import Back from "../common/back/Back"
// import ServicesCard from './ServicesCard'

// const ServicesHome = () => {
//   return (
//     <>
//       <section
//         className="py-12 hero bg-cover text-black flex justify-center"
//         style={{
//           backgroundImage: "url('ap.img.png')",
//           backgroundPosition: "center",
//           backgroundSize: "cover",  
//         }}
//       >
//         <div className="container mx-auto px-4 sm:px-6 lg:px-8">
//           <Back title="Explore Services" />
//           <ServicesCard />
//         </div>
//       </section>
//     </>
//   );
// };

// export default ServicesHome;



import React from "react";
import Back from "../common/back/Back";
import ServicesCard from "./ServicesCard";

const ServicesHome = () => {
  return (
    <>
      <section
        className="py-12 hero bg-cover text-black flex justify-center items-center"
        style={{
          backgroundImage: "url('ap.img.png')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          {/* Back Component */}
          <Back title="Explore Services" />
          
          {/* ServicesCard Component */}
          <div className="mt-8">
            <ServicesCard />
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesHome;
