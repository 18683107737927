import  React ,  { useState } from 'react';
// import { Link } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';


const PersonalityAssessment = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isComplete, setIsComplete] = useState(false);

  const questions = [
    // Logical-Mathematical Intelligence (Questions 1-12)
    {
      id: 0,
      category: "Logical-Mathematical",
      type: "scale",
      question: "When planning a trip, I usually...",
      leftLabel: "Go with the flow and adapt as needed",
      rightLabel: "Create detailed itineraries and backup plans",
      description: "This measures your approach to planning and organization"
    },
    {
      id: 1,
      category: "Logical-Mathematical",
      type: "scale",
      question: "While cooking a new recipe, I tend to...",
      leftLabel: "Experiment and adjust ingredients freely",
      rightLabel: "Measure ingredients precisely",
      description: "This evaluates your precision and methodology"
    },
    {
      id: 2,
      category: "Logical-Mathematical",
      type: "scale",
      question: "When faced with a jigsaw puzzle, I typically...",
      leftLabel: "Try pieces randomly until they fit",
      rightLabel: "Sort pieces by color and edge patterns first",
      description: "This assesses your systematic problem-solving approach"
    },
    {
      id: 3,
      category: "Logical-Mathematical",
      type: "scale",
      question: "If my monthly budget doesn't balance, I...",
      leftLabel: "Adjust spending as issues arise",
      rightLabel: "Create a detailed spreadsheet to track every expense",
      description: "This measures your financial organization skills"
    },
    {
      id: 4,
      category: "Logical-Mathematical",
      type: "scale",
      question: "When learning a new board game, I prefer to...",
      leftLabel: "Learn by playing and making mistakes",
      rightLabel: "Study all the rules before starting",
      description: "This evaluates your learning methodology"
    },
    {
      id: 5,
      category: "Logical-Mathematical",
      type: "scale",
      question: "In a mystery movie or book, I...",
      leftLabel: "Enjoy being surprised by the ending",
      rightLabel: "Try to solve the mystery before the reveal",
      description: "This assesses your analytical thinking"
    },
    {
      id: 6,
      category: "Logical-Mathematical",
      type: "scale",
      question: "When assembling furniture, I typically...",
      leftLabel: "Follow my instinct and experiment",
      rightLabel: "Follow the instruction manual step by step",
      description: "This measures your approach to structured tasks"
    },
    {
      id: 7,
      category: "Logical-Mathematical",
      type: "scale",
      question: "If my routine is disrupted, I...",
      leftLabel: "Easily adapt to the change",
      rightLabel: "Create a new structured plan",
      description: "This evaluates your adaptation to change"
    },
    {
      id: 8,
      category: "Logical-Mathematical",
      type: "scale",
      question: "When shopping for a major purchase, I...",
      leftLabel: "Trust my gut feeling",
      rightLabel: "Research and compare all options systematically",
      description: "This assesses your decision-making process"
    },
    {
      id: 9,
      category: "Logical-Mathematical",
      type: "scale",
      question: "If something breaks at home, I...",
      leftLabel: "Try quick fixes as they come to mind",
      rightLabel: "Diagnose the problem methodically",
      description: "This measures your problem-solving approach"
    },
    {
      id: 10,
      category: "Logical-Mathematical",
      type: "scale",
      question: "When organizing my digital files, I...",
      leftLabel: "Save them where convenient",
      rightLabel: "Create a detailed folder system",
      description: "This evaluates your digital organization skills"
    },
    {
      id: 11,
      category: "Logical-Mathematical",
      type: "scale",
      question: "Playing strategy games like chess or Sudoku...",
      leftLabel: "Feels challenging and stressful",
      rightLabel: "Feels engaging and enjoyable",
      description: "This assesses your comfort with strategic thinking"
    },
    // Linguistic Intelligence (Questions 13-24)
    {
      id: 12,
      category: "Linguistic",
      type: "scale",
      question: "When telling a story about my weekend, I...",
      leftLabel: "Keep it brief and basic",
      rightLabel: "Include vivid details and descriptions",
      description: "This measures your narrative expression"
    },
    {
      id: 13,
      category: "Linguistic",
      type: "scale",
      question: "In group messages or emails, I tend to...",
      leftLabel: "Use simple, short responses",
      rightLabel: "Write detailed, expressive replies",
      description: "This evaluates your written communication style"
    },
    {
      id: 14,
      category: "Linguistic",
      type: "scale",
      question: "If someone uses a word I don't know, I...",
      leftLabel: "Ignore it and move on",
      rightLabel: "Look it up and try to use it later",
      description: "This assesses your vocabulary development approach"
    },
    {
      id: 15,
      category: "Linguistic",
      type: "scale",
      question: "When explaining how to get to my house, I...",
      leftLabel: "Prefer to send a map link",
      rightLabel: "Give detailed verbal directions",
      description: "This measures your verbal instruction ability"
    },
    {
      id: 16,
      category: "Linguistic",
      type: "scale",
      question: "During conversations about movies or books, I...",
      leftLabel: "Mainly listen to others' views",
      rightLabel: "Share detailed opinions and analyses",
      description: "This evaluates your analytical expression"
    },
    {
      id: 17,
      category: "Linguistic",
      type: "scale",
      question: "When resolving a misunderstanding, I...",
      leftLabel: "Find it hard to express my point",
      rightLabel: "Clearly explain my perspective",
      description: "This assesses your communication clarity"
    },
    {
      id: 18,
      category: "Linguistic",
      type: "scale",
      question: "At social gatherings, I...",
      leftLabel: "Prefer to listen to conversations",
      rightLabel: "Enjoy telling stories and jokes",
      description: "This measures your verbal social interaction"
    },
    {
      id: 19,
      category: "Linguistic",
      type: "scale",
      question: "When writing social media posts, I...",
      leftLabel: "Keep them short and simple",
      rightLabel: "Write detailed, thoughtful content",
      description: "This evaluates your written expression style"
    },
    {
      id: 20,
      category: "Linguistic",
      type: "scale",
      question: "If I disagree with someone's opinion, I...",
      leftLabel: "Usually stay quiet",
      rightLabel: "Express my viewpoint with supporting arguments",
      description: "This assesses your argumentative expression"
    },
    {
      id: 21,
      category: "Linguistic",
      type: "scale",
      question: "When sharing an interesting article or video, I...",
      leftLabel: "Just share the link",
      rightLabel: "Add my own detailed commentary",
      description: "This measures your content curation style"
    },
    {
      id: 22,
      category: "Linguistic",
      type: "scale",
      question: "In work emails or messages, I...",
      leftLabel: "Keep them brief and direct",
      rightLabel: "Provide comprehensive context",
      description: "This evaluates your professional communication"
    },
    {
      id: 23,
      category: "Linguistic",
      type: "scale",
      question: "When someone asks about my day, I...",
      leftLabel: "Give a quick summary",
      rightLabel: "Share detailed stories and experiences",
      description: "This assesses your conversational style"
    },
    // Interpersonal Intelligence (Questions 25-36)
    {
      id: 24,
      category: "Interpersonal",
      type: "scale",
      question: "At a gathering where I only know one person, I...",
      leftLabel: "Stick with the person I know",
      rightLabel: "Easily connect with new people",
      description: "This measures your social adaptability"
    },
    {
      id: 25,
      category: "Interpersonal",
      type: "scale",
      question: "When a friend seems upset but hasn't said anything, I...",
      leftLabel: "Wait for them to bring it up",
      rightLabel: "Notice subtle signs and check in with them",
      description: "This evaluates your emotional awareness"
    },
    {
      id: 26,
      category: "Interpersonal",
      type: "scale",
      question: "If there's a disagreement in my friend group, I...",
      leftLabel: "Try to stay out of it",
      rightLabel: "Help find a solution everyone likes",
      description: "This assesses your conflict resolution style"
    },
    {
      id: 27,
      category: "Interpersonal",
      type: "scale",
      question: "At professional networking events, I...",
      leftLabel: "Find it draining to interact",
      rightLabel: "Energetically engage with others",
      description: "This measures your professional networking style"
    },
    {
      id: 28,
      category: "Interpersonal",
      type: "scale",
      question: "When working on team projects, I prefer to...",
      leftLabel: "Handle my part independently",
      rightLabel: "Coordinate regularly with teammates",
      description: "This evaluates your team collaboration style"
    },
    {
      id: 29,
      category: "Interpersonal",
      type: "scale",
      question: "During group discussions about weekend plans, I...",
      leftLabel: "Go along with others' suggestions",
      rightLabel: "Help find options everyone enjoys",
      description: "This assesses your group decision-making role"
    },
    {
      id: 30,
      category: "Interpersonal",
      type: "scale",
      question: "If I notice a coworker struggling with a task, I...",
      leftLabel: "Focus on my own work",
      rightLabel: "Offer support and assistance",
      description: "This measures your workplace empathy"
    },
    {
      id: 31,
      category: "Interpersonal",
      type: "scale",
      question: "When meeting people from different backgrounds, I...",
      leftLabel: "Stick to safe conversation topics",
      rightLabel: "Adapt my communication style",
      description: "This evaluates your cultural adaptability"
    },
    {
      id: 32,
      category: "Interpersonal",
      type: "scale",
      question: "In group settings, I typically...",
      leftLabel: "Listen and observe",
      rightLabel: "Actively participate and share",
      description: "This assesses your group interaction style"
    },
    {
      id: 33,
      category: "Interpersonal",
      type: "scale",
      question: "If someone shares good news with me, I...",
      leftLabel: "Offer brief congratulations",
      rightLabel: "Ask questions and share their excitement",
      description: "This measures your emotional engagement"
    },
    {
      id: 34,
      category: "Interpersonal",
      type: "scale",
      question: "When someone asks for advice, I...",
      leftLabel: "Give quick suggestions",
      rightLabel: "Listen fully and discuss options",
      description: "This evaluates your counseling approach"
    },
    {
      id: 35,
      category: "Interpersonal",
      type: "scale",
      question: "If team members have different opinions, I...",
      leftLabel: "Let others decide",
      rightLabel: "Help find common ground",
      description: "This assesses your conflict mediation ability"
    },
    // Intrapersonal Intelligence (Questions 37-48)
    {
      id: 36,
      category: "Intrapersonal",
      type: "scale",
      question: "After an important conversation, I...",
      leftLabel: "Move on to the next thing",
      rightLabel: "Think about what was said",
      description: "This measures your self-reflection"
    },
    {
      id: 37,
      category: "Intrapersonal",
      type: "scale",
      question: "When setting personal goals, I...",
      leftLabel: "Keep them loose and flexible",
      rightLabel: "Create detailed action plans",
      description: "This evaluates your goal-setting approach"
    },
    {
      id: 38,
      category: "Intrapersonal",
      type: "scale",
      question: "If I'm feeling stressed or upset, I...",
      leftLabel: "Try to ignore it",
      rightLabel: "Take time to understand why",
      description: "This assesses your emotional awareness"
    },
    {
      id: 39,
      category: "Intrapersonal",
      type: "scale",
      question: "When facing a difficult task, I...",
      leftLabel: "Need external motivation",
      rightLabel: "Push myself to complete it",
      description: "This measures your self-motivation"
    },
    {
      id: 40,
      category: "Intrapersonal",
      type: "scale",
      question: "Before making important decisions, I...",
      leftLabel: "Decide quickly and move on",
      rightLabel: "Reflect on potential outcomes",
      description: "This evaluates your decision-making process"
    },
    {
      id: 41,
      category: "Intrapersonal",
      type: "scale",
      question: "At the end of each day, I...",
      leftLabel: "Rarely think about how it went",
      rightLabel: "Reflect on what I learned",
      description: "This assesses your daily reflection habits"
    },
    {
      id: 42,
      category: "Intrapersonal",
      type: "scale",
      question: "When I face setbacks, I...",
      leftLabel: "Try to forget about them",
      rightLabel: "Learn from the experience",
      description: "This measures your growth mindset"
    },
    {
      id: 43,
      category: "Intrapersonal",
      type: "scale",
      question: "While working on personal projects, I...",
      leftLabel: "Prefer guidance from others",
      rightLabel: "Trust my own judgment",
      description: "This evaluates your self-reliance"
    },
    {
      id: 44,
      category: "Intrapersonal",
      type: "scale",
      question: "When learning from mistakes, I...",
      leftLabel: "Prefer not to dwell on them",
      rightLabel: "Analyze what went wrong",
      description: "This assesses your self-improvement approach"
    },
    {
      id: 45,
      category: "Intrapersonal",
      type: "scale",
      question: "If I feel overwhelmed, I...",
      leftLabel: "Push through until it passes",
      rightLabel: "Take time to regroup and plan",
      description: "This measures your stress management"
    },
    {
      id: 46,
      category: "Intrapersonal",
      type: "scale",
      question: "When others want my time or energy, I...",
      leftLabel: "Usually say yes",
      rightLabel: "Consider my own needs first",
      description: "This evaluates your boundary setting"
    },
    {
        id: 47,
        category: "Intrapersonal",
        type: "scale",
        question: "Looking at my future, I...",
        leftLabel: "Take it as it comes",
        rightLabel: "Have clear goals and plans",
        description: "This assesses your future planning approach"
      },
      // Musical Intelligence (Questions 49-60)
      {
        id: 48,
        category: "Musical",
        type: "scale",
        question: "While listening to my favorite songs, I...",
        leftLabel: "Focus mainly on the words",
        rightLabel: "Notice musical patterns and changes",
        description: "This measures your musical perception"
      },
      {
        id: 49,
        category: "Musical",
        type: "scale",
        question: "When hearing a new song, I...",
        leftLabel: "Can rarely repeat the tune",
        rightLabel: "Easily pick up the melody",
        description: "This evaluates your musical memory"
      },
      {
        id: 50,
        category: "Musical",
        type: "scale",
        question: "If there's background music playing, I...",
        leftLabel: "Barely notice it",
        rightLabel: "Find myself moving to the rhythm",
        description: "This assesses your rhythmic sensitivity"
      },
      {
        id: 51,
        category: "Musical",
        type: "scale",
        question: "When watching movies, I...",
        leftLabel: "Focus on the dialogue",
        rightLabel: "Notice how music affects the mood",
        description: "This measures your musical awareness"
      },
      {
        id: 52,
        category: "Musical",
        type: "scale",
        question: "If someone sings off-key, I...",
        leftLabel: "Don't usually notice",
        rightLabel: "Immediately hear the difference",
        description: "This evaluates your pitch recognition"
      },
      {
        id: 53,
        category: "Musical",
        type: "scale",
        question: "While listening to a new genre of music, I...",
        leftLabel: "Find it hard to appreciate",
        rightLabel: "Try to understand its unique elements",
        description: "This assesses your musical adaptability"
      },
      {
        id: 54,
        category: "Musical",
        type: "scale",
        question: "When I hear music from my past, I...",
        leftLabel: "Barely remember it",
        rightLabel: "Recall specific memories and feelings",
        description: "This measures your musical memory association"
      },
      {
        id: 55,
        category: "Musical",
        type: "scale",
        question: "During music-focused events or concerts, I...",
        leftLabel: "Focus on the overall experience",
        rightLabel: "Notice technical details",
        description: "This evaluates your musical analysis ability"
      },
      {
        id: 56,
        category: "Musical",
        type: "scale",
        question: "If asked to clap along to a beat, I...",
        leftLabel: "Often lose the rhythm",
        rightLabel: "Maintain the tempo easily",
        description: "This assesses your rhythmic coordination"
      },
      {
        id: 57,
        category: "Musical",
        type: "scale",
        question: "When hearing multiple instruments, I...",
        leftLabel: "Hear them as one sound",
        rightLabel: "Can distinguish each instrument",
        description: "This measures your musical discrimination"
      },
      {
        id: 58,
        category: "Musical",
        type: "scale",
        question: "If a song's rhythm changes, I...",
        leftLabel: "Rarely notice",
        rightLabel: "Immediately sense the shift",
        description: "This evaluates your rhythmic awareness"
      },
      {
        id: 59,
        category: "Musical",
        type: "scale",
        question: "While learning new songs, I...",
        leftLabel: "Need many repetitions",
        rightLabel: "Pick them up quickly",
        description: "This assesses your musical learning ability"
      },
      // Visual-Spatial Intelligence (Questions 61-72)
      {
        id: 60,
        category: "Visual-Spatial",
        type: "scale",
        question: "When parking my car, I...",
        leftLabel: "Find it challenging",
        rightLabel: "Can easily judge distances",
        description: "This measures your spatial awareness"
      },
      {
        id: 61,
        category: "Visual-Spatial",
        type: "scale",
        question: "After visiting a new place, I...",
        leftLabel: "Need directions to return",
        rightLabel: "Remember the route easily",
        description: "This evaluates your spatial memory"
      },
      {
        id: 62,
        category: "Visual-Spatial",
        type: "scale",
        question: "When rearranging furniture, I...",
        leftLabel: "Need to move pieces to visualize",
        rightLabel: "Can plan it in my head",
        description: "This assesses your mental visualization"
      },
      {
        id: 63,
        category: "Visual-Spatial",
        type: "scale",
        question: "While playing games like Tetris, I...",
        leftLabel: "Find rotations difficult",
        rightLabel: "Easily visualize piece placements",
        description: "This measures your spatial manipulation"
      },
      {
        id: 64,
        category: "Visual-Spatial",
        type: "scale",
        question: "When choosing color combinations, I...",
        leftLabel: "Stick to basic matches",
        rightLabel: "Create interesting palettes",
        description: "This evaluates your visual aesthetics"
      },
      {
        id: 65,
        category: "Visual-Spatial",
        type: "scale",
        question: "Looking at building blueprints, I...",
        leftLabel: "Find them confusing",
        rightLabel: "Easily visualize the space",
        description: "This assesses your spatial interpretation"
      },
      {
        id: 66,
        category: "Visual-Spatial",
        type: "scale",
        question: "When organizing my living space, I...",
        leftLabel: "Focus on function",
        rightLabel: "Consider both function and visual flow",
        description: "This measures your spatial organization"
      },
      {
        id: 67,
        category: "Visual-Spatial",
        type: "scale",
        question: "While watching design shows, I...",
        leftLabel: "Focus on the result",
        rightLabel: "Understand the design choices",
        description: "This evaluates your design thinking"
      },
      {
        id: 68,
        category: "Visual-Spatial",
        type: "scale",
        question: "When giving directions, I...",
        leftLabel: "Prefer written steps",
        rightLabel: "Draw or visualize maps",
        description: "This assesses your spatial communication"
      },
      {
        id: 69,
        category: "Visual-Spatial",
        type: "scale",
        question: "If assembling a model or puzzle, I...",
        leftLabel: "Follow instructions strictly",
        rightLabel: "Visualize how pieces fit",
        description: "This measures your spatial problem-solving"
      },
      {
        id: 70,
        category: "Visual-Spatial",
        type: "scale",
        question: "When designing or decorating, I...",
        leftLabel: "Keep it simple",
        rightLabel: "Create unique visual solutions",
        description: "This evaluates your visual creativity"
      },
      {
        id: 71,
        category: "Visual-Spatial",
        type: "scale",
        question: "While exploring new neighborhoods, I...",
        leftLabel: "Often get turned around",
        rightLabel: "Easily maintain orientation",
        description: "This assesses your spatial orientation"
      },
      // Bodily-Kinesthetic Intelligence (Questions 73-84)
      {
        id: 72,
        category: "Bodily-Kinesthetic",
        type: "scale",
        question: "When learning a new dance move or exercise, I...",
        leftLabel: "Need lots of practice",
        rightLabel: "Pick it up quickly",
        description: "This measures your physical learning ability"
      },
      {
        id: 73,
        category: "Bodily-Kinesthetic",
        type: "scale",
        question: "While working with small objects or tools, I...",
        leftLabel: "Often feel clumsy",
        rightLabel: "Have good control and precision",
        description: "This evaluates your fine motor skills"
      },
      {
        id: 74,
        category: "Bodily-Kinesthetic",
        type: "scale",
        question: "When trying a new physical activity, I...",
        leftLabel: "Feel awkward at first",
        rightLabel: "Adapt naturally",
        description: "This assesses your physical adaptability"
      },
      {
        id: 75,
        category: "Bodily-Kinesthetic",
        type: "scale",
        question: "If I need to stay still for long periods, I...",
        leftLabel: "Find it easy",
        rightLabel: "Need to move or fidget",
        description: "This measures your kinesthetic awareness"
      },
      {
        id: 76,
        category: "Bodily-Kinesthetic",
        type: "scale",
        question: "While playing sports or games, I...",
        leftLabel: "Think about each movement",
        rightLabel: "Move instinctively",
        description: "This evaluates your physical intuition"
      },
      {
        id: 77,
        category: "Bodily-Kinesthetic",
        type: "scale",
        question: "When expressing emotions, I...",
        leftLabel: "Mainly use words",
        rightLabel: "Use gestures and body language",
        description: "This assesses your physical expression"
      },
      {
        id: 78,
        category: "Bodily-Kinesthetic",
        type: "scale",
        question: "In physical activities requiring balance, I...",
        leftLabel: "Feel unsteady",
        rightLabel: "Maintain stability easily",
        description: "This measures your balance control"
      },
      {
        id: 79,
        category: "Bodily-Kinesthetic",
        type: "scale",
        question: "When learning by doing, I...",
        leftLabel: "Prefer written instructions",
        rightLabel: "Like hands-on practice",
        description: "This evaluates your kinesthetic learning"
      },
      {
        id: 80,
        category: "Bodily-Kinesthetic",
        type: "scale",
        question: "While walking or running, I...",
        leftLabel: "Sometimes trip or stumble",
        rightLabel: "Move smoothly and confidently",
        description: "This assesses your gross motor control"
      },
      {
        id: 81,
        category: "Bodily-Kinesthetic",
        type: "scale",
        question: "During hands-on projects, I...",
        leftLabel: "Prefer following guides",
        rightLabel: "Trust my physical intuition",
        description: "This measures your physical problem-solving"
      },
      {
        id: 82,
        category: "Bodily-Kinesthetic",
        type: "scale",
        question: "If I need to lift or carry something, I...",
        leftLabel: "Misjudge the weight",
        rightLabel: "Automatically adjust my effort",
        description: "This evaluates your physical awareness"
      },
      {
        id: 83,
        category: "Bodily-Kinesthetic",
        type: "scale",
        question: "When sitting for long periods, I...",
        leftLabel: "Rarely adjust position",
        rightLabel: "Stay aware of posture and comfort",
        description: "This assesses your body awareness"
      },
      // Naturalist Intelligence (Questions 85-92)
      {
        id: 84,
        category: "Naturalist",
        type: "scale",
        question: "While walking outdoors, I...",
        leftLabel: "Focus on getting there",
        rightLabel: "Notice plants and wildlife",
        description: "This measures your environmental awareness"
      },
      {
        id: 85,
        category: "Naturalist",
        type: "scale",
        question: "When the weather changes, I...",
        leftLabel: "Barely notice patterns",
        rightLabel: "Sense upcoming changes",
        description: "This evaluates your natural pattern recognition"
      },
      {
        id: 86,
        category: "Naturalist",
        type: "scale",
        question: "If plants aren't thriving, I...",
        leftLabel: "Not sure why",
        rightLabel: "Can identify likely causes",
        description: "This assesses your environmental problem-solving"
      },
      {
        id: 87,
        category: "Naturalist",
        type: "scale",
        question: "When observing animals, I...",
        leftLabel: "See general behavior",
        rightLabel: "Notice subtle patterns",
        description: "This measures your naturalistic observation"
      },
      {
        id: 88,
        category: "Naturalist",
        type: "scale",
        question: "While choosing produce, I...",
        leftLabel: "Pick what looks okay",
        rightLabel: "Know what to look for",
        description: "This evaluates your natural selection ability"
      },
      {
        id: 89,
        category: "Naturalist",
        type: "scale",
        question: "In different environments, I...",
        leftLabel: "Take time to adjust",
        rightLabel: "Adapt naturally",
        description: "This assesses your environmental adaptation"
      },
      {
        id: 90,
        category: "Naturalist",
        type: "scale",
        question: "When seasons change, I...",
        leftLabel: "Notice major changes",
        rightLabel: "Observe subtle transitions",
        description: "This measures your natural awareness"
      },
      {
        id: 91,
        category: "Naturalist",
        type: "scale",
        question: "If ecosystem changes occur, I...",
        leftLabel: "Rarely notice effects",
        rightLabel: "Understand the connections",
        description: "This evaluates your ecological thinking"
      },
      // Learning and Expression Preferences (Questions 93-100)
      {
        id: 92,
        category: "Learning",
        type: "preference",
        question: "When trying to remember directions, I prefer...",
        options: [
          "Following a written list",
          "Listening to verbal instructions",
          "Walking the route once",
          "Looking at a map"
        ],
        description: "This assesses your directional learning preference"
      },
      {
        id: 93,
        category: "Learning",
        type: "preference",
        question: "While learning new software, I learn best by...",
        options: [
          "Reading the manual",
          "Watching tutorial videos",
          "Playing around with features",
          "Following guided exercises"
        ],
        description: "This measures your technical learning style"
      },
      {
        id: 94,
        category: "Learning",
        type: "preference",
        question: "When sharing an idea at work, I prefer to...",
        options: [
          "Write a detailed email",
          "Present to the group",
          "Create a diagram or visual",
          "Demonstrate hands-on"
        ],
        description: "This evaluates your communication preference"
      },
      {
        id: 95,
        category: "Learning",
        type: "preference",
        question: "When remembering events, I mostly recall...",
        options: [
          "What people said",
          "How things looked",
          "How I felt",
          "What I did"
        ],
        description: "This measures your memory processing style"
      },
      {
        id: 96,
        category: "Learning",
        type: "preference",
        question: "While giving feedback, I prefer to...",
        options: [
          "Write detailed notes",
          "Have a conversation",
          "Show examples",
          "Demonstrate corrections"
        ],
        description: "This evaluates your feedback delivery style"
      },
      {
        id: 97,
        category: "Learning",
        type: "preference",
        question: "When feeling stressed, I find relief through...",
        options: [
          "Writing thoughts down",
          "Talking with others",
          "Physical activity",
          "Quiet reflection"
        ],
        description: "This assesses your stress management approach"
      },
      {
        id: 98,
        category: "Learning",
        type: "preference",
        question: "To learn a new skill, I prefer...",
        options: [
          "Reading about it first",
          "Watching others do it",
          "Trying it myself",
          "Understanding the theory"
        ],
        description: "This measures your skill acquisition preference"
      },
      {
        id: 99,
        category: "Learning",
        type: "preference",
        question: "When solving complex problems, I typically...",
        options: [
          "Break them down logically",
          "Discuss with others",
          "Sketch or diagram solutions",
          "Try different approaches"
        ],
        description: "This evaluates your problem-solving approach"
      }
  ];

  const handleScaleSelect = (value) => {
    setAnswers({
      ...answers,
      [currentQuestion]: value
    });
  };

  const handlePreferenceSelect = (option) => {
    setAnswers({
      ...answers,
      [currentQuestion]: option
    });
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setIsComplete(true);
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const transformResults = (answers) => {
    // Calculate category averages
    const calculateCategoryAverage = (category) => {
      const categoryQuestions = questions.filter(q => q.category === category);
      const categoryAnswers = categoryQuestions.map(q => answers[q.id] || 0);
      return categoryAnswers.reduce((a, b) => a + b, 0) / categoryAnswers.length;
    };
  
    // Intelligence scores for the charts
    const intelligenceScores = [
      { name: "Logical", score: calculateCategoryAverage("Logical-Mathematical") * 10, fullMark: 100 },
      { name: "Linguistic", score: calculateCategoryAverage("Linguistic") * 10, fullMark: 100 },
      { name: "Interpersonal", score: calculateCategoryAverage("Interpersonal") * 10, fullMark: 100 },
      { name: "Intrapersonal", score: calculateCategoryAverage("Intrapersonal") * 10, fullMark: 100 },
      { name: "Visual", score: calculateCategoryAverage("Visual-Spatial") * 10, fullMark: 100 },
      { name: "Musical", score: calculateCategoryAverage("Musical") * 10, fullMark: 100 },
      { name: "Kinesthetic", score: calculateCategoryAverage("Bodily-Kinesthetic") * 10, fullMark: 100 },
      { name: "Naturalist", score: calculateCategoryAverage("Naturalist") * 10, fullMark: 100 }
    ];
  
    // Calculate overall score and strengths/weaknesses
    const overallScore = (intelligenceScores.reduce((sum, item) => sum + item.score, 0) / intelligenceScores.length).toFixed(1);
    const strongestArea = [...intelligenceScores].sort((a, b) => b.score - a.score)[0].name;
    const developmentArea = [...intelligenceScores].sort((a, b) => a.score - b.score)[0].name;
  
    // Detailed attributes
    const attributes = {
      abilities: [
        { 
          name: "Working With Numbers", 
          left: "Low", 
          right: "High", 
          value: calculateCategoryAverage("Logical-Mathematical"), 
          scale: 10 
        },
        { 
          name: "Working With Words", 
          left: "Low", 
          right: "High", 
          value: calculateCategoryAverage("Linguistic"), 
          scale: 10 
        },
        { 
          name: "Working With Shapes", 
          left: "Low", 
          right: "High", 
          value: calculateCategoryAverage("Visual-Spatial"), 
          scale: 10 
        }
      ],
      motivation: [
        { name: "Working with People", left: "Low", right: "High", value: calculateCategoryAverage("Interpersonal"), scale: 10 },
        { name: "Working with Data", left: "Low", right: "High", value: calculateCategoryAverage("Logical-Mathematical"), scale: 10 },
        { name: "Working with Things", left: "Low", right: "High", value: calculateCategoryAverage("Bodily-Kinesthetic"), scale: 10 }
      ],
      personality: [
        { name: "Diplomatic-Independent", left: "Diplomatic", right: "Independent", value: calculateCategoryAverage("Interpersonal"), scale: 10 },
        { name: "Cooperative-Competitive", left: "Cooperative", right: "Competitive", value: calculateCategoryAverage("Interpersonal"), scale: 10 },
        { name: "Spontaneous-Conscientious", left: "Spontaneous", right: "Conscientious", value: calculateCategoryAverage("Logical-Mathematical"), scale: 10 },
        { name: "Introvert-Extrovert", left: "Introvert", right: "Extrovert", value: calculateCategoryAverage("Interpersonal"), scale: 10 },
        { name: "Emotional-Stable", left: "Emotional", right: "Stable", value: calculateCategoryAverage("Intrapersonal"), scale: 10 }
      ]
    };
  
    return {
      intelligenceScores,
      attributes,
      overallScore,
      strongestArea,
      developmentArea
    };
  };
  const renderAttributeScale = (attribute) => (
    <div key={attribute.name} className="mb-8">
      {/* Category Name */}
      <h3 className="text-lg font-semibold text-customBlue mb-4">
        {attribute.name}
      </h3>
      
      {/* Scale labels */}
      <div className="flex justify-between text-sm mb-1">
        <div>
          <span className="font-medium text-customBlue">{attribute.left}</span>
          <div className="text-xs text-customBlue">Low Score Indicator</div>
        </div>
        <div className="text-right">
          <span className="font-medium text-customBlue">{attribute.right}</span>
          <div className="text-xs text-customBlue">High Score Indicator</div>
        </div>
      </div>
  
      {/* Score display */}
      <div className="text-center mb-2">
        <span className="text-2xl font-bold text-customBlue">{attribute.value.toFixed(1)}</span>
        <span className="text-customBlue text-sm">/10</span>
      </div>
      
      {/* Scale container */}
      <div className="relative">
        {/* Number scale */}
        <div className="flex justify-between mb-2 px-1 text-xs text-customBlue">
          {[...Array(11)].map((_, i) => (
            <span key={i} className="font-medium">{i}</span>
          ))}
        </div>
        
        {/* Scale background */}
        <div className="h-2 bg-gray-200 rounded-full relative">
          {/* Blue filled portion */}
          <div 
            className="absolute top-0 left-0 h-full bg-customBlue rounded-full"
            style={{ width: `${(attribute.value / 10) * 100}%` }}
          />
          
          {/* Circle indicator */}
          <div 
            className="absolute top-1/2 -translate-y-1/2 w-4 h-4 bg-white rounded-full border-2 border-customBlue shadow"
            style={{ left: `calc(${(attribute.value / 10) * 100}% - 8px)` }}
          />
        </div>
  
        {/* Tick marks */}
        <div className="flex justify-between absolute w-full top-6 px-1">
          {[...Array(11)].map((_, i) => (
            <div key={i} className="w-px h-1 bg-customBlue" />
          ))}
        </div>
      </div>
    </div>
  );
  
  const renderResults = () => {
    const results = transformResults(answers);
  
    return (
      <div className="min-h-screen  py-8 px-4"
      style={{ backgroundImage: "url('ap.img.png')" }}>
        <div className="max-w-5xl mx-auto ">
          {/* Header Section */}
          <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
            <div className="flex items-center justify-between mb-6">
              <h1 className="text-3xl font-bold text-customBlue">Candidate Identification Report (CIR)</h1>
              <div className="text-right">
                <div className="text-sm text-customBlue">Assessment Date</div>
                <div className="text-lg font-semibold text-customBlue">{new Date().toLocaleDateString()}</div>
              </div>
            </div>
            
            <div className="grid grid-cols-3 gap-6 mb-6">
              <div className="bg-blue-50 rounded-lg p-4">
                <div className="text-sm text-customBlue">Overall Score</div>
                <div className="text-2xl font-bold text-blue-500">{results.overallScore}</div>
              </div>
              <div className="bg-green-50 rounded-lg p-4">
                <div className="text-sm text-customBlue">Strongest Area</div>
                <div className="text-lg font-bold text-green-600">{results.strongestArea}</div>
              </div>
              <div className="bg-purple-50 rounded-lg p-4">
                <div className="text-sm text-customBlue">Development Area</div>
                <div className="text-lg font-bold text-purple-600">{results.developmentArea}</div>
              </div>
            </div>
          </div>
  
          <div className="mb-8">
  <h2 className="text-xl font-semibold mb-6 text-black">Multiple Intelligences Profile</h2>
  <div className="w-full bg-white p-4 rounded-lg">
    <BarChart
      width={800}
      height={300}
      data={results.intelligenceScores}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid 
        strokeDasharray="3 3" 
        vertical={false}
      />
      <XAxis 
        dataKey="name" 
        axisLine={false}
        tickLine={false}
        tick={{ fill: '#004D60', fontSize: 12 }}
      />
      <YAxis 
      domain={[0, 100]}
      ticks={[0, 20, 40, 60, 80, 100]}
      axisLine={false}
      tickLine={false}
      tick={{ fill: '#004D60', fontSize: 12 }}
      />
      <Tooltip 
        cursor={{ fill: 'transparent' }}
        contentStyle={{ 
          backgroundColor: 'white', 
          border: '1px solid #eee',
          borderRadius: '4px',
          padding: '8px'
        }}
      />
     
      <Bar dataKey="score" 
        fill="#0d7a95"
        radius={[4, 4, 0, 0]}
        maxBarSize={60}/>
      </BarChart>
  </div>
</div>
  
          {/* Detailed Attributes Sections */}
          <div className="space-y-8">
            {/* Abilities */}
            <section className="bg-white rounded-lg shadow-lg p-8">
              <h2 className="text-2xl font-bold mb-6 text-customBlue">
                <span className="inline-block w-2 h-8 bg-blue-500 rounded-full mr-3 align-middle"></span>
                Abilities
              </h2>
              <div className="space-y-6">
                {results.attributes.abilities.map(renderAttributeScale)}
              </div>
            </section>
  
            {/* Motivation/Interests */}
            <section className="bg-white rounded-lg shadow-lg p-8">
              <h2 className="text-2xl font-bold mb-6 text-customBlue">
                <span className="inline-block w-2 h-8 bg-green-500 rounded-full mr-3 align-middle"></span>
                Motivation/Interests
              </h2>
              <div className="space-y-6">
                {results.attributes.motivation.map(renderAttributeScale)}
              </div>
            </section>
  
            {/* Personality Traits */}
            <section className="bg-white rounded-lg shadow-lg p-8">
              <h2 className="text-2xl font-bold mb-6 text-customBlue">
                <span className="inline-block w-2 h-8 bg-purple-500 rounded-full mr-3 align-middle"></span>
                Personality Traits
              </h2>
              <div className="space-y-6">
                {results.attributes.personality.map(renderAttributeScale)}
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  };
  const progress = ((currentQuestion + 1) / questions.length) * 100;
  const renderAssessment = () => (
    <div className="min-h-screen  py-8 px-72"
      style={{ backgroundImage: "url('ap.img.png')" }}>
      <div className="bg-white bg-opacity-80 rounded-lg shadow-lg p-6 " >
        <div className="mb-6">
          <h1 className="text-2xl font-bold text-center mb-2 text-customBlue">
            Conductivity - Personality Assessment
          </h1>
          <div className="w-full bg-gray-50 rounded-full h-2">
            <div
              className="bg-customBlue h-2 rounded-full transition-all duration-300"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <p className="text-center text-sm text-customBlue mt-2">
            Question {currentQuestion + 1} of {questions.length}
          </p>
        </div>
  
        <div className="bg-customBlue text-white p-4 mb-6 rounded">
          <h2 className="text-lg font-semibold">
            {questions[currentQuestion].category}
          </h2>
          <p className="text-sm mt-1 opacity-80">
            {questions[currentQuestion].description}
          </p>
        </div>
  
        <div className="mb-8">
          <p className="text-lg mb-8 text-center text-customBlue">
            {questions[currentQuestion].question}
          </p>
  
          <div className="space-y-8">
            {questions[currentQuestion].type === 'scale' ? (
              <>
                <div className="flex justify-between text-sm text-customBlue px-2">
                  <span>{questions[currentQuestion].leftLabel}</span>
                  <span>{questions[currentQuestion].rightLabel}</span>
                </div>
                <div className="flex justify-between gap-2">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                    <button
                      key={value}
                      onClick={() => handleScaleSelect(value)}
                      className={`w-10 h-10 rounded-full flex items-center justify-center transition-all duration-200 ${
                        answers[currentQuestion] === value
                          ? 'bg-customBlue text-white'
                          : 'bg-gray-100 hover:bg-gray-200'
                      }`}
                    >
                      {value}
                    </button>
                  ))}
                </div>
              </>
            ) : (
              <div className="space-y-4"
              >
                {questions[currentQuestion].options.map((option) => (
                  <button
                    key={option}
                    onClick={() => handlePreferenceSelect(option)}
                    className={`w-full p-3 text-left rounded transition-all duration-200 ${
                      answers[currentQuestion] === option
                        ? 'bg-customBlue text-white'
                        : 'bg-gray-100 hover:bg-gray-200'
                    }`}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
  
        <div className="flex justify-between">
          <button
            onClick={handlePrevious}
            disabled={currentQuestion === 0}
            className="px-4 py-2 rounded bg-customBlue hover:bg-TealBlue disabled:opacity-50 text-white"
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            className="px-4 py-2 rounded bg-customBlue text-white hover:bg-TealBlue"
          >
            {currentQuestion === questions.length - 1 ? 'Complete' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );

  return isComplete ? renderResults() : renderAssessment();
};

export default PersonalityAssessment;
