// import React from 'react';


// const Hero = () => {
//   return (
//     <>
//       <section
//         className="hero bg-cover h-screen flex items-center justify-center"
//         style={{
//           backgroundImage: "url('hero.png')",
//           backgroundPosition: "center center",  
//           backgroundSize: "cover", 
//         }}
//       >
//         <div className="container mx-auto px-4 sm:px-6 lg:px-8 ">
//           <div className="row flex flex-col items-center text-center text-white space-y-6 ">
//             <h1 className='text-2xl sm:text-3xl md:text-4xl font-bold mt-2'>Connecting Passion with Profession</h1>
            
//             <p className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-center max-w-xl mx-auto leading-relaxed">
//             Your Professional Journey Begins Here
//               <br />
//               With expert guidance and support, Sanjeeda promises to guide you through every step for your career advancement and professional growth
//             </p>
            
//             <div className="flex flex-col sm:flex-row sm:space-x-5 space-y-4 sm:space-y-0 justify-center">
//               <button className="primary-btn bg-TealBlue text-white w-full sm:w-auto px-4 py-3 rounded hover:bg-customBlue transition text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">
//                Let’s get started
//               </button>
//               <button className="primary-btn bg-TealBlue text-white w-full sm:w-auto px-3 py-3 rounded hover:bg-customBlue transition text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">
//                 VIEW COURSE
//               </button>
//             </div>
//           </div>
//         </div>
//       </section>

//       <div className="margin">
//         <div className="service-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-4 sm:p-8">
//           <div className="image-box bg-gray-200 shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 relative">
//             <img src="c18.jpeg" alt="" className="w-full h-auto rounded-lg" />
//           </div>
//           <div className="image-box bg-gray-200 shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 relative">
//             <img src="c9.jpeg" alt="" className="w-full h-auto rounded-lg" />
//           </div>
//           <div className="image-box bg-gray-200 shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 relative">
//             <img src="c8.jpeg" alt="" className="w-full h-auto rounded-lg" />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Hero;

import React from 'react';
import HAbout from "../HAbout";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
    <HAbout/>
      {/* Hero Section */}
      <section
        className="hero bg-cover h-screen flex items-center justify-center "
        // style={{
        //   backgroundImage: "url('hero.png')",
        //   backgroundPosition: "center center ",
        //   backgroundSize: "cover ",
        // }}
      >
       
       <div className="container mx-auto px-6 sm:px-6 lg:px-8 ">
        
     
          <div className="row flex flex-col items-center text-center text-Blue space-y-6">
         
            {/* Title */}
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold -mt-64">
              Sanjeed<span className="text-Red">a!</span> Stands For:
            </h1>

            {/* Description */}
            <p className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-center max-w-xl mx-auto leading-relaxed text-Red  ">
              Career devlopment for people serious about 
              {/* <br />
              <span className="text-Blue">Readiness </span> | <span className="text-Blue">Opportunities</span> | <span className="text-Blue">Success</span> */}
              {/* With expert guidance and support, Sanjeed<span className="text-[#081b9c]">a!</span> promises to guide you
              through every step for your career advancement and professional growth. */}
            </p>
            <div className=" font-bold ">
            <div className= "text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-center max-w-xl mx-auto leading-relaxed text-Red ">
              <Link to="/readiness" className="text-black hover:underline">
              <span className="text-Blue shadow-Red">Readiness</span> |
              </Link>
              <Link to="/opportunities" className="text-black  hover:underline "><span className="text-Blue"> Opportunities</span> | </Link>
              
              <Link to="/success" className="text-black hover:underline"><span className="text-Blue">Success</span></Link>
            </div>
            </div>

            {/* Buttons
            <div className="flex flex-col sm:flex-row sm:space-x-5 space-y-4 sm:space-y-0 justify-center">
              <button className="primary-btn bg-Blue  text-white w-full sm:w-auto px-3 py-3 rounded hover:bg-[#5768d7] transition text-sm sm:text-base md:text-lg lg:text-xl">
                Let’s get started
              </button>
              <button className="primary-btn bg-Blue text-white w-full sm:w-auto px-6 py-3 rounded hover:bg-[#5768d7] transition text-sm sm:text-base md:text-lg lg:text-xl">
                View Course
              </button>
            </div> */}
            
          </div>
          
        </div>
     
      </section>
      
       <div className="margin h-8 bg-transparent" ></div>
       
    </>
  );
};

export default Hero;
