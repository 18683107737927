// import React from 'react';
// import { Link } from 'react-router-dom';

// const RegistrationForm = () => {
//   return (
//     <div
//       className="flex items-center justify-center min-h-screen bg-cover bg-center"
//       style={{ backgroundImage: "url('ap.img.png')" }}
//     >
    
//       <div className="bg-white bg-opacity-80 p-8 rounded-lg shadow-lg h-full max-w-3xl ">
//         <h1 className="text-2xl font-bold text-center mb-6 text-TealBlue">Registration Form</h1>
//         <form action="#" method="POST" className="space-y-3">
//           <div className="form-group">
//             <label htmlFor="first-name" className="block font-semibold mb-1 text-TealBlue">
//               Name
//             </label>
//             <div className="flex gap-4">
//               <input
//                 type="text"
//                 id="first-name"
//                 name="first-name"
//                 placeholder="First Name"
//                 className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
//               />
//               <input
//                 type="text"
//                 id="last-name"
//                 name="last-name"
//                 placeholder="Last Name"
//                 className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
//               />
//             </div>
//           </div>

//           <div className="form-group">
//             <label htmlFor="mobile-number" className="block font-semibold mb-1 text-TealBlue">
//               Mobile Number
//             </label>
//             <input
//               type="text"
//               id="mobile-number"
//               name="mobile-number"
//               placeholder="(000) 000-0000"
//               className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="address" className="block font-semibold mb-1 text-TealBlue">
//               Address
//             </label>
//             <input
//               type="text"
//               id="address"
//               name="address"
//               placeholder="Street Address"
//               className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email" className="block font-semibold mb-1 text-TealBlue">
//               Email Address
//             </label>
//             <input
//               type="text"
//               id="email"
//               name="email"
//               placeholder="Email"
//               className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
//             />
//           </div>
          

//           <Link 
//           to="/regist-home">
//           <button
//             type="submit"
            
//             className="bg-customBlue text-white font-semibold py-2 px-44 rounded-md hover:bg-opacity-90 transition-all duration-300 mt-6"> 
          
//             Submit
//           </button>
//           </Link>
         
//           {/* <div className="flex gap-4 justify-center mt-6">
//       <i className="fab fa-google text-3xl text-customBlue hover:text-red-500 transition-colors cursor-pointer"></i>
//       <i className="fab fa-facebook text-3xl text-customBlue hover:text-blue-600 transition-colors cursor-pointer"></i>
//     </div> */}
//         </form>
       
//       </div>
//     </div>
//   );
// };

// export default RegistrationForm;

import React from 'react';
import { Link } from 'react-router-dom';

const RegistrationForm = () => {
  return (
    <div
      className="flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('ap.img.png')" }}
    >
      <div className="bg-white bg-opacity-80 p-8 rounded-lg shadow-lg h-full max-w-3xl">
        <h1 className="text-2xl font-bold text-center mb-6 text-TealBlue">
          Registration Form
        </h1>
        <form action="#" method="POST" className="space-y-3">
          <div className="form-group">
            <label htmlFor="first-name" className="block font-semibold mb-1 text-TealBlue">
              Name
            </label>
            <div className="flex gap-4">
              <input
                type="text"
                id="first-name"
                name="first-name"
                placeholder="First Name"
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
              />
              <input
                type="text"
                id="last-name"
                name="last-name"
                placeholder="Last Name"
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="mobile-number" className="block font-semibold mb-1 text-TealBlue">
              Mobile Number
            </label>
            <input
              type="text"
              id="mobile-number"
              name="mobile-number"
              placeholder="(000) 000-0000"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
            />
          </div>

          <div className="form-group">
            <label htmlFor="address" className="block font-semibold mb-1 text-TealBlue">
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              placeholder="Street Address"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email" className="block font-semibold mb-1 text-TealBlue">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-customBlue"
            />
          </div>

          <div className="form-group ">
            <label htmlFor="role" className="block font-semibold mb-1 text-TealBlue">
              Select Your Role
            </label>
            <select
              id="role"
              name="role"
              className="w-full px-3 py-2 border  rounded focus:outline-none focus:ring-2 focus:ring-customBlue text-gray-500 "
            >
              <option value="" disabled selected>
                -- Select Role --
              </option>
              <option value="student">Student</option>
              <option value="mentor">Mentor</option>
              <option value="parent">Parent</option>
              <option value="job-seeker">Job Seeker</option>
            </select>
          </div>

          <div className="flex justify-between">
            <Link to="/regist-home">
              <button
                type="submit"
                className="bg-customBlue text-white font-semibold py-2 px-4 rounded-md hover:bg-opacity-90 transition-all duration-300"
              >
                Submit
              </button>
            </Link>
            

            {/* <button
              type="reset"
              className="bg-gray-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-opacity-90 transition-all duration-300"
            >
              Reset
            </button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;
